import React, { useState } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { makeStyles } from "@material-ui/core"
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Slide from '@material-ui/core/Slide';

import InfoIcon from '@material-ui/icons/Info';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import dayjs from 'dayjs';
import SwipeableViews from 'react-swipeable-views';

import { Formik } from 'formik';

import Form from './components/Form';
import Preview from './components/Preview';
import Welcome from './components/Welcome';
import Thankyou from './components/Thankyou';

import { createStaycationOffer } from '../../api'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
  },
  tabContent: {
    paddingTop: 104,
    '@media (min-width: 600px)': {
      paddingTop: 112,
    }
  },
  logoImage: {
    height: 50,
    marginRight: 40,
    [theme.breakpoints.down('md')]: {
      height: 30,
    },
  },
  headerIcon: {
    color: 'white',
  },
  title: {
    flex: 1,
    color: 'white',
    [theme.breakpoints.down('md')]: {
      fontSize: `1.2em`,
    },
  }
}))

function TabPanel(props) {
  const classes = useStyles();
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <div className={classes.tabContent}>
          {children}
        </div>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

export const createInitialOffer = () => {
  return {
    type: '',
    title: '',
    description: ``,
    image: '',
    expire: dayjs(),
  }
}

const OfferFormPage = () => {

  const [showWelcome, setShowWelcome] = useState(true);
  const handleCloseWelcome = () => setShowWelcome(false);


  const [showThankyou, setShowThankyou] = useState(false);
  const goToListing = () => {
    setShowThankyou(false);
    window.location.href = "https://bcctstaycation.com/"
  }

  const [showError, setShowError] = useState(null);
  const closeError = () => setShowError(null);

  const classes = useStyles();

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const submitForm = async (values, { resetForm, setSubmitting }) => {
    try {
      await createStaycationOffer(values);
      setSubmitting(false);
      setShowThankyou(true);
      resetForm();
    } catch (err) {
      setShowError(err.message);
      setSubmitting(false);
    }
  }

  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.down('md'));

  const initialValues = {
    hotel: {
      name: '',
      summary: '',
      coverImage: '',
      googlemapLink: '',
      email: '',
      phone: '',
      website: '',
      lineId: '',
      whatsapp: '',
      fbmessenger: '',
    },
    offers: [
      createInitialOffer()
    ],
    'other-information': '',
  }

  const validate = values => {
    const errors = {};

    if (!values.hotel.name) _.set(errors, 'hotel.name', 'Hotel name is Required');
    if (!values.hotel.email) _.set(errors, 'hotel.email', 'Contact Email is Required');

    if (values?.hotel.googlemapLink.indexOf(' ') !== -1) _.set(errors, 'hotel.googlemapLink', 'Value cannot contain space');
    if (values?.hotel.email.indexOf(' ') !== -1) _.set(errors, 'hotel.email', 'Value cannot contain space');
    if (values?.hotel.lineId.indexOf(' ') !== -1) _.set(errors, 'hotel.lineId', 'Value cannot contain space');
    if (values?.hotel.whatsapp.indexOf(' ') !== -1) _.set(errors, 'hotel.whatsapp', 'Value cannot contain space');
    if (values?.hotel.fbmessenger.indexOf(' ') !== -1) _.set(errors, 'hotel.fbmessenger', 'Value cannot contain space');

    const offers = _.get(values, 'offers', []);
    _.forEach(offers, (offer, index) => {
      if (!offer.type) _.set(errors, `offers.${index}.type`, 'Offer Type is Required');
      if (!offer.title) _.set(errors, `offers.${index}.title`, 'Offer name is Required');
    });

    return errors;
  };


  return (
    <>
      <Formik
        initialValues={initialValues}
        validate={validate}
        onSubmit={submitForm}
      >
        {({
          handleSubmit,
          isSubmitting,
        }) => {
          return (
            <div className={classes.root}>
              <AppBar position="fixed" color={"primary"} elevation={0}>
                <Toolbar>
                  <div>
                    <img src="/images/bcct-logo.png" alt="BCCT" className={classes.logoImage} />
                  </div>
                  <Typography variant="h5" noWrap className={classes.title}>
                    Create Offer
                    <IconButton onClick={() => setShowWelcome(true)}><InfoIcon className={classes.headerIcon} /></IconButton>
                  </Typography>
                  <Button
                    variant="contained"
                    color="default"
                    disabled={isSubmitting}
                    onClick={handleSubmit}
                  >
                    Submit Offer
                  </Button>
                </Toolbar>
                {smScreen && (
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="Navigation Tab"
                    textColor="inherit"
                    indicatorColor="secondary"
                  >
                    <Tab label="Setup" {...a11yProps(0)} />
                    <Tab label="Preview" {...a11yProps(1)} />
                  </Tabs>
                )}
              </AppBar>
              {smScreen ? (
                <SwipeableViews
                  axis={'x'}
                  index={value}
                  onChangeIndex={handleChangeIndex}
                  containerStyle={{ height: '100vh' }}
                >
                  <TabPanel value={value} index={0}>
                    <Form />
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <Preview />
                  </TabPanel>
                </SwipeableViews>
              ) : (
                <Grid container style={{ paddingTop: 64, height: `calc(100vh - 64)` }}>
                  <Grid item sm={6} style={{ height: `calc(100vh - 64px)`, overflowX: 'hidden', overflowY: 'scroll' }}>
                    <Form />
                  </Grid>
                  <Grid item sm={6} style={{ height: `calc(100vh - 64px)`, overflowX: 'hidden', overflowY: 'scroll' }}>
                    <Preview />
                  </Grid>
                </Grid>
              )}
            </div>
          )
        }}
      </Formik>
      <Dialog fullScreen open={showWelcome} onClose={handleCloseWelcome} TransitionComponent={Transition}>
         <Welcome onStart={handleCloseWelcome} />
      </Dialog>
      <Dialog open={showThankyou} maxWidth={`md`} TransitionComponent={Transition}>
         <Thankyou onOk={goToListing} />
      </Dialog>
      <Dialog
        open={showError !== null}
        onClose={closeError}
      >
        <DialogTitle>{"Something went wrong"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Your submission cannot go through, something went wrong.
            {showError}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeError} color="primary">
            Cancel
          </Button>
          <Button onClick={closeError} color="primary" autoFocus>
            Edit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default OfferFormPage;