import React from 'react';
import _ from 'lodash';
import { makeStyles, withStyles } from "@material-ui/core/styles"
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { FieldArray, useFormikContext } from 'formik';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import FormField from '../../../components/FormField';
import ErrorFocus from '../../../components/ErrorFocus'

import { createInitialOffer } from '../index';

const AddOfferButton = withStyles(theme => ({
  root: {
    padding: `36px 48px`,
    border: `1px dashed ${theme.palette.primary.main}`,
  }
}))(Button);

const useOfferItemStyles = makeStyles({
  root: {
    display: 'flex',
    marginBottom: 50,
    position: 'relative'
  },
  header: {
    // borderTop: `1px solid #dadada`,
    textAlign: `left`,
    padding: `1em 0 1em`,
    textTransform: `uppercase`
  },
  content: {
    flex: 1,
  },
  toolbar: {
    width: 50,
    '@media (min-width: 768px)': {
      position: 'absolute',
      top: 0,
      left: '100%'
    }
  }
})

const useSectionHeaderStyles = makeStyles({
  root: {
    borderTop: `1px solid #dadada`,
    textAlign: `center`,
    margin: `40px auto 20px`,
    maxWidth: 400,
    '@media (min-width: 768px)': {
      margin: `60px auto 40px`,

    } 
  },
  text: {
    position: `relative`,
    top: `-15px`,
    background: `white`,
    display: `inline-block`,
    paddingLeft: 20,
    paddingRight: 20,
  }
})

const defaultOptions = [
  { value: '', label: 'Please select'},
  { value: 'general', label: `General offers & promotions` },
  { value: 'fb', label: `Food & Beverage promotions` },
  { value: 'bcct-exclusive', label: `British Chamber exclusive offers & promotions` },
  { value: 'asq', label: `Alternative state quarantine (ASQ) offers` },
  { value: 'other', label: `Others` },
]

export const SectionHeader = props => {
  const classes = useSectionHeaderStyles();
  return(
    <div className={classes.root}>
      <Typography className={classes.text} variant="overline">{props.text}</Typography>
    </div>
  )
}

const OfferItem = ({ arrayHelpers, index }) => {
  const classes = useOfferItemStyles();
  const {
    values,
  } = useFormikContext();

  const { offers = [] } = values;
  const selectedOptions = _.compact(offers).map(opt => opt.type);

  const options = defaultOptions.map(option => {
    if (selectedOptions.indexOf(option.value) > -1) return { ...option, disabled: true };
    return option;
  })

  return (
    <>
      <div className={classes.header}>
        <Typography variant="caption" display="block" gutterBottom>Offer {index + 1}</Typography>
      </div>
      <div className={classes.root}>
        <div className={classes.content}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormField
                type="image"
                name={`offers.${index}.image`}
                helperText="Image size must be smaller than 3MB"
              />
            </Grid>
            <Grid item xs={12}>
              <FormField
                name={`offers.${index}.type`}
                label="What kind of offers do you have for our members?"
                fullWidth
                type="select"
                options={options}
              />
              <FormField
                name={`offers.${index}.title`}
                label="Your offer"
                helperText="Please clearly separate out your offers adding relevant information, links, room types, discount etc."
                fullWidth
              />
              <FormField
                name={`offers.${index}.description`}
                label="Description"
                helperText=""
                fullWidth
                multiline
                rowsMax={4}
              />
              <FormField
                name={`offers.${index}.expire`}
                type="date"
                label="When will your offers end"
                helperText="If this is blank, the offer will continue until further notice"
                fullWidth
              />
            </Grid>
          </Grid>
        </div>
        <div className={classes.toolbar}>
          {index !== 0 && (
            <Tooltip title="Remove Offer" placement="top">
              <IconButton color="secondary" onClick={() => arrayHelpers.remove(index)}>
                <RemoveCircleIcon fontSize="large" />
              </IconButton>
            </Tooltip>
          )}
          {/* <Tooltip title="Add another Offer" placement="top">
            <IconButton color="primary" onClick={() => arrayHelpers.push()}>
              <AddCircleIcon fontSize="large" />
            </IconButton>
          </Tooltip> */}
        </div>
      </div>
    </>
  )
}
const OfferForm = () => {
  const {
    values,
    handleSubmit,
  } = useFormikContext();

  return (
    <Container maxWidth="sm">
      <form onSubmit={handleSubmit}>
        <SectionHeader text="Hotel Info" />
        <ErrorFocus />
        <FormField
          fullWidth
          type="image"
          name="hotel.coverImage"
          helperText="Image size must be smaller than 3MB"
        />
        <FormField
          fullWidth
          className="hotel-name"
          name="hotel.name"
          label="What is the name of your hotel?"
          helperText="If you represent more than one hotel, please submit multiple entries"
        />
        <FormField
          fullWidth
          className="hotel-summary" 
          name="hotel.summary"
          multiline
          rowsMax={4}
          label="Could you summarise your hotel in a short paragraph for us?"
          helperText='E.g: “The best 5 star hotel experience in Mayfair with stunning rooms and suites, afternoon tea, Michelin-starred dining and private dining at The Ritz."'
        />
        <FormField
          fullWidth
          className="hotel-googlemapLink"
          name="hotel.googlemapLink"
          label="What’s the Google map link to your hotel?"
          helperText="This is so we can automatically build a map link for you"
        />
        <SectionHeader text="Offers" />
        {/* <FormField
          fullWidth
          type="checkboxes-group"
          className="offers"
          name="available_offers"
          label="Offer"
          options={defaultOptions}
        /> */}
        <FieldArray
          name="offers"
          render={arrayHelpers => (
            <div>
              {values.offers && values.offers.length > 0 && (
                values.offers.map((offer, index) => (
                  <OfferItem key={`offer-item-${index}`} arrayHelpers={arrayHelpers} index={index} />
                ))
              )}
              <AddOfferButton
                color="primary"
                size="large"
                variant="contained"
                fullWidth
                onClick={() => arrayHelpers.push(createInitialOffer())}
                startIcon={<AddCircleIcon />}
              >
                Add another Offer
              </AddOfferButton>
            </div>
          )}
        />
        <SectionHeader text="Contact Info" />
        <FormField
          fullWidth
          className="hotel-email"
          name="hotel.email"
          label="What email address should people contact you via?"
          helperText="For guests who prefer to contact you via email"
        />
        <FormField
          fullWidth
          className="hotel-phone"
          name="hotel.phone"
          label="What is the phone number for making bookings?"
          helperText="For guests who prefer to contact you via phone"
        />
        <FormField
          fullWidth
          className="hotel-website"
          name="hotel.website"
          label="Is there a link on your website for your current deals?"
          helperText="Please make sure that any deals you’d like us to promote are also on your website"
        />
        <FormField
          fullWidth
          className="hotel-fbmessenger"
          name="hotel.fbmessenger"
          label="What is your Facebook/Messenger URL?"
          helperText="or guests who prefer to contact you via Facebook or Messenger"
        />
        {/* <FormField
          fullWidth
          className="hotel-whatsapp"
          name="hotel.whatsapp"
          label="What is your Whatsapp business phone number?"
          helperText="For guests who prefer to contact you via Whatsapp"
        /> */}
         <FormField
          fullWidth
          className="hotel-lineId"
          name="hotel.lineId"
          label="What is your LINE ID?"
          helperText="For guests who prefer to contact you via LINE"
        />
        <FormField
          name="other-information"
          label="Do you have anything else you’d like to add?"
          helperText="Please add any other information here"
          fullWidth
          multiline
          rowsMax={4}
        />
      </form>
    </Container>
  )
}

export default OfferForm;