import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#164590'
    },
    secondary: {
      main: '#ff0000'
    },
    text: {
      primary: '#546285',
    }
  },
  typography: {
    h2: {
      fontWeight: 700,
      fontFamily: 'Arial, sans-serif',
    },
    h3: {
      fontWeight: 700,
      fontFamily: 'Arial, sans-serif',
    },
    h4: {
      fontWeight: 700,
      fontFamily: 'Arial, sans-serif',
    },
    h5: {
      fontWeight: 700,
      fontFamily: 'Arial, sans-serif',
      letterSpacing: -0.8,
    }
  },
  overrides: {
    MuiButton: {
      sizeLarge: {
        padding: `20px 35px`,
        minWidth: 300,
      }
    },
    MuiFormControl: {
      root: {
        marginBottom: '1em'
      }
    },
    MuiFilledInput: {
      root: {
        backgroundColor: 'transparent'
      }
    },
    MuiFormLabel: {
      root: {
        color: '#164590'
      }
    }
  },
  props: {
    MuiTextField: {
      variant: 'filled'
    },
    MuiButton: {
      disableElevation: true,
    }
  }
});

export default theme;