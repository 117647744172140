import React from 'react'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { makeStyles } from "@material-ui/core"


const useStyles = makeStyles(theme => ({
  welcomeText: {
    padding:`40px 60px 40px 120px`,
    [theme.breakpoints.down('md')]: {
      padding: `40px`,
    },
  },
  welcomeImage: {
    background: `url(images/beach.jpeg)`,
    backgroundSize: `cover`,
    height: `100%`,
    width: `100%`,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  welcomeHeader: {
    background: theme.palette.primary.main,
    padding:`20px 20px 20px 120px`,
  },
  logo: {
    width: 300,
  }
}));

export default function Welcome({ onStart }) {
  const classes = useStyles();
  return (
    <div>
      <Grid container spacing={0}>
        <Grid item md={12} lg={7}>
          <div className={classes.welcomeHeader}>
            <img src="/images/bcct-logo.png" className={classes.logo} alt="Logo" />
          </div>  
          <Box className={classes.welcomeText}>            
            <Typography variant="h4" paragraph>How can the BCCT help your hotel?</Typography>
            <Typography variant="body1" paragraph>As part of our ongoing commitment to supporting our Hotel Members, the BCCT has launched an online ‘Staycation Guide' to help our 2,375+ local Chamber members and their families find and book rooms, restaurants and spa deals at your hotel. It's a great way to get your latest offers out to a large section of the local market, mainly expat clients who are on the look-out for staycations and great deals.
            </Typography>
            <Typography variant="body1" paragraph>The guide is completely free to join, with zero commission to pay on bookings made. All bookings are made directly with your hotel, and any offers in the guide cannot be seen by OTA's rate shopping tools.
            </Typography>
            <Typography variant="body1" paragraph>To join up and add your offers, please complete the form below. Feel free to add as many offers as you like for any aspect of your hotel – outlets, long stay, AQ, conferences, weddings – whatever you prefer. Offers can be given expiry dates and will be removed automatically after the date has passed.
            </Typography>
            <Typography variant="body1" paragraph>You offers will be viewable on bcctstaycation.com, and will look the same as the preview pane on this page. Once you've submitted the form, the BCCT will review the content and publish within 2 working days. If there are any issues, we'll reach out to you directly.
            </Typography>
            <Typography variant="body1" paragraph>Please note, you are responsible for managing your own contact. For technical assistance please contact the BCCT
            </Typography>
            <Button variant="contained" color="primary" size="large" onClick={onStart}>Ok</Button>
          </Box>
        </Grid>
        <Grid item sm={12} md={12} lg={5}>
          <div className={classes.welcomeImage} />
        </Grid>
      </Grid>
    </div>
  )
}