import React from 'react';
import DateFnsUtils from '@date-io/dayjs';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

export default function MaterialUIPickers(props) {

  const { name, label, value, onChange, ...rest } = props;
  const handleDateChange = (date) => {
    onChange({
      target: {
        name,
        value: date,
      },
    })
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        format="DD/MM/YYYY"
        margin="normal"
        label={label}
        value={value}
        onChange={handleDateChange}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        {...rest}
      />
    </MuiPickersUtilsProvider>
  );
}