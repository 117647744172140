import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  formControl: {
    margin: theme.spacing(3),
  },
}));
const CheckboxesGroup = props => {

  const { label, value = [], options, name, onChange } = props;
  const classes = useStyles();

  const handleChange = (event) => {
    const selectedKey = event.target.name;
    const newValue = value.indexOf(selectedKey) === -1 ? [...value, selectedKey] : value.filter(v => v !== selectedKey);
    onChange({
      target: {
        name,
        value: newValue,
      }
    })
  };

  return (
    <FormControl required component="fieldset" className={classes.formControl}>
      <FormLabel component="legend">{label}</FormLabel>
      <FormGroup>
        {options.map(option => {
          const checked = value.indexOf(option.value) > -1;
          return (
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={checked}
                  onChange={handleChange}
                  name={option.value}
                />
              }
              label={option.label}
            />
          );
        })}
      </FormGroup>
    </FormControl>
  )
}

export default CheckboxesGroup;
