import React from 'react';
import get from 'lodash/get'
import keys from 'lodash/keys'
import { connect } from 'formik';

class ErrorFocus extends React.Component {
  componentDidUpdate(prevProps) {
    const { isSubmitting, isValidating, errors } = prevProps.formik;

    // Hotels
    const hotelKeys = keys(errors.hotel);
    if (hotelKeys.length > 0 && isSubmitting && !isValidating) {
      const selector = `[name="hotel.${hotelKeys[0]}"]`;
      const errorElement = document.querySelector(selector);
      errorElement.focus();
    } else {
      // Offers
      const offers = get(errors, 'offers', []);
      offers.forEach((offer, index) => {
        const offerKeys = keys(offer);
        if (offerKeys.length > 0 && isSubmitting && !isValidating) {
          const selector = `[name="offers.${index}.${offerKeys[0]}"]`;
          const errorElement = document.querySelector(selector);
          errorElement.focus();
        }
      })
    }
  }

  render() {
    return null;
  }
}

export default connect(ErrorFocus);