import { ThemeProvider } from '@material-ui/core/styles';
import theme from './theme';
import './App.css';

import StaycationOfferForm from './pages/StaycationOfferForm';


function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="app">
        <StaycationOfferForm />
      </div>
    </ThemeProvider>
  );
}

export default App;
