import { useFormikContext } from 'formik';
import dayjs from 'dayjs'

import { SectionHeader } from './Form';

const Preview = () => {
  const { values } = useFormikContext();
  const muted = { opacity: 0.3 }
  const coverImageStyle = {
    width: 'auto',
    backgroundImage: `url(${values.hotel?.coverImage})`,
    backgroundSize: `cover`,
    backgroundPosition: `center center`,
    backgroundRepeat: `no-repeat`,
  }

  const placeholderImageStyle = {
    background: '#f7f7f7',
    border: `1px solid #ececec`,
    color: `#aaa`,
    display: `flex`,
    justifyContent: `center`,
    alignItems: `center`,
    width: `auto`,
    height: 300,
  }
  return (
    <div className="preview">
      <SectionHeader text="Preview" />

      {values.hotel?.coverImage ? (
        <div className="cover-image" style={coverImageStyle}></div>
      ) : (
        <div style={placeholderImageStyle}>Hotel Image will appear here.</div>
      )}
      <div className="container">
        <h1 className="hotel-name">{values?.hotel?.name || <span style={muted}>Hotel Name</span>}</h1>
        <div className="hotel-summary">{values?.hotel?.summary || <span style={muted}>A nice paragraph to introduce your hotel</span>}</div>
        <section className="hotel-contacts">
          <h4 className="heading3">How to book:</h4>
          <div className="info-row">
            <div className="info">Email</div>
            <div className="value">{values?.hotel.email || <span style={muted}>youremail@hotel.com</span>}</div>
          </div>
          <div className="info-row">
            <div className="info">Phone Number</div>
            <div className="value">{values?.hotel.phone || <span style={muted}>###-###-####</span>}</div>
          </div>
          <div className="info-row">
            <div className="info">Website</div>
            <div className="value">{values?.hotel.website || <span style={muted}>yourhotelwebsite.com</span>}</div>
          </div>
          <div className="info-row">
            <div className="info">Facebook/Messenger</div>
            <div className="value">{values?.hotel.fbmessenger}</div>
          </div>
          <div className="info-row">
            <div className="info">LINE</div>
            <div className="value">{values?.hotel.lineId}</div>
          </div>
          <div className="info-row">
            <div className="info">Whatsapp</div>
            <div className="value">{values?.hotel.whatsapp}</div>
          </div>
        </section>
        <section>
          {values.offers.length > 0 && values.offers.map((offer, index) =>  (
            <div className="offer" key={`offer-${index}`}>
              <div className="offer-image-wrapper">
                {offer?.image ? <img src={offer?.image} className="offer-image" alt="offer" /> : <div style={placeholderImageStyle}>Offer related image</div>}
              </div>
              <div className="offer-body">
                <h3 className="offer-title">{offer?.title || <span style={muted}>Offer name</span>}</h3>
                <div className="offer-description">{offer?.description ||  <span style={muted}>Detail of what you offer</span>}</div>
                {dayjs(offer?.expire).isValid() && (
                  <div className="offer-date">
                    <strong>Offer valid until:</strong>
                    <p>{dayjs(offer?.expire).format('MMM D, YYYY')}</p>
                  </div>
                )}
              </div>
            </div>
          ))}
          {values.offers.length === 0 && (
            <div style={placeholderImageStyle}>Your offer will be here</div>
          )}
        </section>
      </div>
    </div>
  )
}

export default Preview;