import React from 'react';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

export default function SelectField(props) {
  const { name, value = ``, onChange, options, ...rest } = props;

  const handleChange = e => {
    onChange({
      target: {
        name,
        value: e.target.value,
      }
    })
  }

  return (
    <TextField
      name={name}
      select
      value={value}
      onChange={handleChange}
      {...rest}
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value} disabled={option.disabled}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  )
}