import React from 'react'
import axios from "axios"
import _ from 'lodash';
import { makeStyles } from "@material-ui/core"
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import ImageIcon from "@material-ui/icons/Image"
import DeleteIcon from '@material-ui/icons/Delete';
import FormHelperText from '@material-ui/core/FormHelperText';

const useStyles = makeStyles((theme) => ({
  previewContainer: {
    position: 'relative',
    width: "100%",
    // height: "35vw",
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(2),
    },
  },
  previewImage: {
    width: "100%",
  },
  overlayActions: {
    position: 'absolute',
    bottom: 12,
    left: 12,
    width: `auto`,
    zIndex: 1,
  },
  iconContainer: {
    marginBottom: theme.spacing(1.7),
  },
  uploadBoxContainer: {
    background: '#ececec',
    border: '1px dashed #ccc',
    borderRadius: 6,
    width: "100%",
    height: "25vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& input": {
      display: 'none',
    },
    "& label": {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(2),
    },
  },
}))

const ImageField = ({ name, value, onChange, ...rest }) => {
  const classes = useStyles();
  const inputFileRef = React.useRef()
  const index = 0;

  const handleImageUpload = () => {
    const { files } = inputFileRef.current
    const formData = new FormData()
    formData.append("file", files[0])
    formData.append(
      "upload_preset",
      `${process.env.REACT_APP_CLOUDINARY_PRESETS}`
    )
    axios
      .post(
        `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUDNAME}/image/upload/`,
        formData
      )
      .then((res) => {
        onChange({
          target: {
            name,
            value: res.data.secure_url,
          },
        })
      })
      .catch((err) => console.log(err))
  }

  const removeImage = () => {
    onChange({
      target: {
        name,
        value: ``,
      },
    })
  }

  const imageController = value ? (
    <div className={classes.previewContainer}>
      <img
        className={classes.previewImage}
        src={value}
        alt={`img-${index}`}
      />
      <div className={classes.overlayActions}>
        <Tooltip title="Remove Image" placement="left">
          <Button variant="contained" size="small" color="secondary" startIcon={<DeleteIcon />} onClick={removeImage}>
            Delete
          </Button>
        </Tooltip>
      </div>
    </div>
  ) : (
    <div className={classes.uploadBoxContainer}>
      <input
        accept="image/*"
        type="file"
        name="file"
        id={`file-upload`}
        ref={inputFileRef}
        onChange={handleImageUpload}
      />
      <label htmlFor={`file-upload`}>
        <IconButton aria-label="upload picture" component="span">
          <ImageIcon />
        </IconButton>
      </label>
    </div>
  )

  return (
    <div>
      {imageController}
      <input type="hidden" name={name} value={value} />
      <FormHelperText>{rest.helperText}</FormHelperText>
    </div>
  )
}

export default ImageField