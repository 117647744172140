import _ from 'lodash'

const parseDate = date => {
  return (date && date.isValid()) ? date.toISOString() : '';
}

const parseSubmissionData = values => {

  const generalOffer = _.find(values.offers, o => o.type === 'general');
  const fbOffer = _.find(values.offers, o => o.type === 'fb');
  const asqOffer = _.find(values.offers, o => o.type === 'asq');
  const bcctExclusiveOffer = _.find(values.offers, o => o.type === 'bcct-exclusive');
  const otherOffer = _.find(values.offers, o => o.type === 'other');

  console.log({ values, generalOffer, fbOffer, asqOffer, bcctExclusiveOffer, otherOffer })
  const body = {
    "_archived": false,
    "_draft": true,
    "name": _.get(values, `hotel.name`),
    "summary": _.get(values, `hotel.summary`),
    "slug":  _.kebabCase(_.get(values, `hotel.name`)),
    "hotel-photo": _.get(values, `hotel.coverImage`),
    ...(generalOffer ? {
      "general-offers": _.get(generalOffer, `title`),
      "general-offers-description": _.get(generalOffer, `description`),
      "general-offer-image": _.get(generalOffer, `image`),
      "offers-valid-until": parseDate(_.get(generalOffer, `expire`)),
    } : {}),
    ...(asqOffer ? {
      "alternative-state-quarantine-asq-offers": _.get(asqOffer, `title`),
      "asq-offers-description": _.get(asqOffer, `description`),
      "asq-offer-image": _.get(asqOffer, `image`),  
      "asq-offers-valid-until": parseDate(_.get(asqOffer, `expire`)),
    } : {}),
    ...(bcctExclusiveOffer ? {
      "bcct-exclusive-offers": _.get(bcctExclusiveOffer, `title`),
      "bcct-exclusive-offers-description": _.get(bcctExclusiveOffer, `description`),
      "bcct-offer-image": _.get(bcctExclusiveOffer, `image`),  
      "bcct-exclusive-offers-value-until": parseDate(_.get(bcctExclusiveOffer, `expire`)),
    } : {}),
    ...(fbOffer ? {
      "f-b-offers": _.get(fbOffer, `title`),
      "f-b-offers-description": _.get(fbOffer, `description`),
      "f-b-offer-image": _.get(fbOffer, `image`),  
      "f-b-offer-valid-until": parseDate(_.get(fbOffer, `expire`)),
    } : {}),
    ...(otherOffer ? {
      "other-offers": _.get(otherOffer, `title`),
      "other-offers-description": _.get(otherOffer, `description`),
      "other-offer-image": _.get(otherOffer, `image`),  
      "other-offer-valid-until": parseDate(_.get(otherOffer, `expire`)),
    } : {}),
    "email": _.get(values, `hotel.email`, ``),
    "phone-number": _.get(values, `hotel.phone`, ``),
    "facebook": _.get(values, `hotel.fbmessenger`, ``),
    "google-map-link": _.get(values, `hotel.googlemapLink`, ``),
    "hotel-website": _.get(values, `hotel.website`, ``),
    "line-id": _.get(values, `hotel.lineId`, ``),
    // "whatsapp": _.get(values, `hotel.whatsapp`, ``),
    "other-information": _.get(values, `other-information`, ``),
  }
  return body;
}

export const createStaycationOffer = async values => {
  try {
    const body = parseSubmissionData(values);
    console.log({ values, body })
    const response = await fetch(`/.netlify/functions/submit-webflow`, {
      method: 'PUT',
      body: JSON.stringify({
        fields: body
      })
    })

    const jsonResponse = await response.json();
    return jsonResponse;

  } catch (err) {
    console.log(err);
    throw err;
  }
}