import React from 'react'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { makeStyles } from "@material-ui/core"


const useStyles = makeStyles(theme => ({
  root: {
    position: `relative`,
    padding: `40px 80px`,
    minWidth: 600,
    height: `400px`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  thankyouHeader: {
    marginBottom: `${theme.spacing(4)}px`
  },
  thankyouText: {
    marginBottom: `${theme.spacing(4)}px`
  }
}));

export default function Thankyou({ onOk }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography variant="h4" className={classes.thankyouHeader}>Thank you for submitting your information</Typography>
      <Typography variant="body1" className={classes.thankyouText}>
      You offers will be viewable on bcctstaycation.com, and will look the same as the preview pane on this page. The BCCT will now review the content and publish within 2 working days. If there are any issues, we'll reach out to you directly.
If you would like to changed anything, or need technical assistance please email rungjit@bccthai.com or contact the BCCT.
      </Typography>
      <Button variant="contained" color="primary" size="large" onClick={onOk}>OK</Button>
    </div>
  )
}