import React from 'react'
import get from 'lodash/get'
import { Field } from 'formik';

import TextField from '@material-ui/core/TextField';
import SelectField from './Select';
import ImageField from './Image'
import DateField from './Date';
import CheckboxesGroup from './CheckboxesGroup';

const FormField = ({ name, type, ...rest }) => {
  let InputComponent;
  switch (type) {
    case 'image': InputComponent = ImageField; break;
    case 'date': InputComponent = DateField; break;
    case 'select': InputComponent = SelectField; break;
    case 'checkboxes-group': InputComponent = CheckboxesGroup; break;
    default: InputComponent = TextField; break;
  }
  return (
    <Field name={name}>
      {({
        field, 
        form: { touched, errors },
        meta,
      }) => {
        return (
          <>
            <InputComponent
              {...field}
              {...rest}
              error={get(errors, name) !== undefined}
              helperText={get(touched, name) ? get(errors, name, rest.helperText) : rest.helperText}
            />
          </>
        )
      }}
    </Field>
  );
}

export default FormField;